import type {Dates} from '@hconnect/uikit'
import {SimplePickerComponents as SPC} from '@hconnect/uikit/src/lib2'
import {QuickSelectionItem} from '@hconnect/uikit/src/lib2/components/simpleDatePicker/types'
import DateRange from '@mui/icons-material/DateRange'
import {Checkbox, Popover, Box, FormControlLabel, Theme, ButtonProps} from '@mui/material'
import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

type DateRange = [Date | null, Date | null]

export const getResponsivePopoverStyle = (theme: Theme) => ({
  marginTop: 2,
  color: theme.palette.primary.contrastText,
  '@media only screen and (max-width: 410px)': {
    display: 'flex',
    justifyContent: 'center',
    left: '0 !important',
    width: '100%',
    maxWidth: '100%'
  }
})

interface Props {
  className?: string
  date?: Date | null
  dateRange?: DateRange
  isOutsideRange?: (arg: Date) => boolean
  label?: React.ReactNode
  onChange: (value: Dates<Date> | Date) => void
  quickSelectionItems?: QuickSelectionItem[]
  selectedItem?: QuickSelectionItem
  onQuickSelection?: (item: QuickSelectionItem | null) => void
  DatePickerTextFieldProps?: ButtonProps
}

const formatDate = (date: Date, language: string, textFormatter?: (d: Date) => string): string =>
  textFormatter ? textFormatter(date) : date?.toLocaleDateString(language)

enum TYPES {
  DATE = 'date',
  DATE_RANGE = 'dateRange'
}

const getSelectionType = (date, dateRange) => {
  if (date) return TYPES.DATE
  if (dateRange) return TYPES.DATE_RANGE
  throw new Error('no value selected in date picker')
}

const RangeDatePicker = (props: Props) => {
  const {
    date,
    dateRange = [],
    isOutsideRange,
    onChange,
    className,
    quickSelectionItems,
    selectedItem,
    onQuickSelection,
    DatePickerTextFieldProps
  } = props
  const {t, i18n} = useTranslation()
  const {language} = i18n
  const isSingleDate = date !== undefined
  const [startDate, endDate] = dateRange
  const [selectedStartDate, setSelectedStartDate] = useState(date || startDate || null)
  const [selectedEndDate, setSelectedEndDate] = useState(endDate)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [isRangeSelect, setShowRangeSelect] = useState(!isSingleDate)
  const [selectionType, setType] = useState(getSelectionType(date, dateRange))
  const noDateText = t('datePicker.chooseDate')
  const [showCalendar, setShowCalendar] = useState(true)

  const selectionText = useMemo(() => {
    if (selectedItem) {
      return selectedItem.title
    }
    if ((selectionType === TYPES.DATE || !selectedEndDate) && selectedStartDate) {
      return formatDate(selectedStartDate, language)
    }
    if (selectionType === TYPES.DATE_RANGE && selectedStartDate && selectedEndDate) {
      return `${formatDate(selectedStartDate, language)} - ${formatDate(selectedEndDate, language)}`
    }
    return noDateText
  }, [selectedItem, selectionType, selectedEndDate, selectedStartDate, noDateText, language])

  const close = useCallback(() => setOpen(false), [setOpen])

  const handleDateRangeChange = (dates: Dates<Date>) => {
    setSelectedStartDate(dates.startDate || null)
    setSelectedEndDate(dates.endDate || null)

    if (dates.endDate) {
      onChange(dates)
      setType(TYPES.DATE_RANGE)
    }
  }

  const handleSingleDateChange = (date: Date | null) => {
    if (!date) return
    setSelectedStartDate(date)
    setSelectedEndDate(date)
    onChange(date)
    setType(TYPES.DATE)
  }

  const calendarProps = {
    isOutsideRange,
    startDate: selectedStartDate,
    ...(isRangeSelect
      ? {
          endDate: selectedEndDate,
          onRangeChange: (dates) => {
            onQuickSelection?.(null)
            handleDateRangeChange(dates)
          }
        }
      : {onDateChange: handleSingleDateChange})
  }

  return (
    <Box
      sx={{
        display: 'flex'
      }}
      className={className}
    >
      <SPC.DatePickerTextField
        buttonRef={anchorRef}
        focus={open}
        setFocus={setOpen}
        text={selectionText}
        label={t('datePicker.label')}
        {...DatePickerTextFieldProps}
      />

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        slotProps={{
          paper: {
            sx: getResponsivePopoverStyle,
            id: 'simple-date-picker-popover-paper'
          }
        }}
        data-test-id="simple-date-picker-popover"
      >
        <Box
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 356,
            gap: 2
          }}
        >
          <>
            {quickSelectionItems && (
              <SPC.TabBar setShowCalendar={setShowCalendar} showCalendar={showCalendar} />
            )}
            {showCalendar ? (
              <>
                <SPC.Calendar {...calendarProps} />
                <Box
                  sx={(theme) => ({
                    color: theme.palette.text.primary,
                    width: '100%'
                  })}
                >
                  <FormControlLabel
                    sx={{mb: 1}}
                    control={
                      <Checkbox
                        data-test-id="range-select"
                        checked={isRangeSelect}
                        onChange={() => setShowRangeSelect((prev) => !prev)}
                      />
                    }
                    label={'Select Range'}
                  />
                  <SPC.PickerForm
                    applyDateRange={(value) => {
                      onQuickSelection?.(null)
                      handleDateRangeChange(value)
                      close()
                    }}
                    isOutsideRange={isOutsideRange}
                    isSingleDate={!isRangeSelect}
                    endDate={selectedEndDate}
                    startDate={selectedStartDate}
                  />
                </Box>
              </>
            ) : (
              <Box
                display="flex"
                px={3}
                sx={{
                  width: 356,
                  '& button': {
                    textAlign: 'left',
                    py: 2,
                    borderBottom: '1px solid #E6E6E6',
                    '& span': {
                      color: '#29333D',
                      fontSize: '16px',
                      fontWeight: 400
                    }
                  }
                }}
              >
                <SPC.QuickSelection
                  handleItemClick={(dates, item) => {
                    onQuickSelection?.(item)
                    handleDateRangeChange(dates)
                    close()
                  }}
                  selectedItem={selectedItem}
                  items={quickSelectionItems}
                  dateCustomTemplate={() => ''}
                />
              </Box>
            )}
          </>
        </Box>
      </Popover>
    </Box>
  )
}

export {RangeDatePicker}
