import {ReactComponent as Illustration} from '@hconnect/uikit/src/lib2/pages/illustration500.svg'
import {Card, CardContent, CardHeader, Grid, GridProps, CircularProgress, Box} from '@mui/material'
import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'

export type WidgetTemplateProps = Omit<GridProps, 'item' | 'zeroMinWidth'> & {
  title: string
  headerAction?: React.ReactNode
  loading?: boolean
}

export const WidgetTemplate = ({
  title,
  children,
  headerAction,
  onClick,
  loading,
  sx = {},
  ...gridProps
}: WidgetTemplateProps) => {
  return (
    <Grid
      item
      zeroMinWidth
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        ...sx
      }}
      {...gridProps}
    >
      <Card sx={{padding: 3, pr: 1, height: '100%', display: 'flex', flexDirection: 'column'}}>
        <CardHeader
          action={headerAction}
          title={title}
          titleTypographyProps={{variant: 'h3'}}
          sx={{
            padding: 0,
            pr: 2,
            alignItems: 'center',
            marginBottom: 3
          }}
        />
        <CardContent
          sx={{
            padding: 0,
            pr: 2,
            alignItems: 'center',
            textAlign: 'center',
            overflow: 'auto'
          }}
        >
          <ErrorBoundary fallback={<Illustration style={{width: '100%'}} viewBox="0 0 401 316" />}>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height={200}>
                <CircularProgress />
              </Box>
            ) : (
              children
            )}
          </ErrorBoundary>
        </CardContent>
      </Card>
    </Grid>
  )
}
