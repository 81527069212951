import {UseQueryResult} from '@tanstack/react-query'

export enum FilterTypes {
  Select = 'select',
  SelectPredefined = 'select-predefined',
  Text = 'text',
  Date = 'date'
}

export enum FilterActionType {
  ADD_CHOICE,
  ADD_DATE_SINGLE,
  ADD_DATE_RANGE,
  ADD_TEXT,
  REMOVE,
  REMOVE_ALL,
  SET_FILTER_VISIBLE,
  UPDATE_CONFIG,
  CLEAR_VALUE
}

export enum FilterActionOperation {
  EQUAL = 'EqualTo',
  NOT_EQUAL = 'NotEqualTo',
  STARTS_WITH = 'StartsWith',
  ENDS_WITH = 'EndsWith',
  CONTAINS = 'Contains',
  GREATER = 'GreaterThan',
  LESS = 'LessThan',
  GREATER_OR_EQUAL = 'GreaterOrEqual',
  LESS_OR_EQUAL = 'LessOrEqual',
  BETWEEN = 'Between',
  NOT_BETWEEN = 'NotBetween',
  BLANK = 'IsBlank',
  NOT_BLANK = 'NotIsBlank',
  IN = 'In',
  NOT_IN = 'NotIn'
}

type CommonFilterConfig = {
  id: string
  label: string
  available: boolean
  defaultVisible?: boolean
}

type SelectFilterConfig = CommonFilterConfig & {
  type: FilterTypes.Select
  apiUrl?: string
  apiParams?: object
  multipleOptions?: boolean
  optionsLoader: SelectOptionsLoader
}

export type SelectOptionsLoader = (inputs: any) => UseQueryResult<AutocompleteOption[], unknown>

type SelectPredefinedFilterConfig = CommonFilterConfig & {
  type: FilterTypes.SelectPredefined
  options: AutocompleteOption[]
  multipleOptions?: boolean
}

type DateFilterConfig = CommonFilterConfig & {
  type: FilterTypes.Date
  rangeAvailable?: boolean
}

type TextFilterConfig = CommonFilterConfig & {
  type: FilterTypes.Text
}

export type FilterConfig =
  | SelectFilterConfig
  | SelectPredefinedFilterConfig
  | DateFilterConfig
  | TextFilterConfig

export type FilterOptionVisibility = FilterConfig & {
  visible: boolean
  apiFilter?: ApiFilter
}

export type DateRange = [Date | null, Date | null]

export type AutocompleteOption = {
  id: string
  label: string
  customComponent?: React.ReactNode
}

export interface Filter<T> {
  id: string
  label: string
  handleChange: any // fill in proper type
  value?: T | Array<T> | null
}

export type ApiFilter = {
  property: string
  values: string[]
  operation: FilterActionOperation
}

export type FilterAction = {
  type: FilterActionType
  payload: {
    filterId: string
    value: any
  }
}

export type FilterState = {
  [key: string]: FilterOptionVisibility
}
